export default {
  init() {
    // JavaScript to be fired on all pages
    $('#mobile-menu-button').on('click', function(e){
      e.stopPropagation();
      $('.nav-primary').toggleClass('expanded');
      $('#mobile-menu').toggleClass('expanded');
      const expanded = $('#mobile-menu').hasClass('expanded');
      $('#mobile-menu').attr('aria-expanded', expanded ? 'true' : 'false');
    });
    $(window).on('resize', removeExpanded);
    $(document).on('click', removeExpanded);
    function removeExpanded(){
      $('.nav-primary').removeClass('expanded');
      $('#mobile-menu').removeClass('expanded');
      $('#mobile-menu').attr('aria-expanded', 'false');
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
